import React from "react"
import { Stack, StackItem, Text, Link, DefaultButton, PrimaryButton, Image, FontWeights, TextStyles } from "office-ui-fabric-react"
//Template started off with index.css
import "./index.css"
//Template started off with "boldStyle" CSS-in-JS and I added more
const boldStyle = { root: { fontWeight: FontWeights.semibold } }
const underlineStyle = { root: { textDecorationLine: 'underline' } }
const backgroundImageStyle = { root: { backgroundImage:"url('https://images.unsplash.com/photo-1577831299331-139dafa4e7f7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80')", flex: 1, resizeMode: 'cover' /*or 'stretch'*/ } }
const stackCardStyle = { root: { backgroundColor: "#fff", width:'100%' } }
const heartStyle = { root: { color: "#ff0000" } }

//I added in a theme: https://developer.microsoft.com/en-us/fluentui?fabricVer=6#/controls/web/themes
import { loadTheme } from 'office-ui-fabric-react/lib/Styling';
loadTheme({
  palette: {
    themePrimary: '#0078d4',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#c2c2c2',
    neutralSecondary: '#858585',
    neutralPrimaryAlt: '#4b4b4b',
    neutralPrimary: '#333333',
    neutralDark: '#272727',
    black: '#1d1d1d',
    white: '#ffffff'
  }
});

// GraphQL and gatsby-image
import { graphql } from "gatsby"
import Img from "gatsby-image"
export const query = graphql`
  query {
    photoProcess: file(relativePath: { eq: "photo-1532619187608-e5375cab36aa.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    photoSpiderman: file(relativePath: { eq: "photo-1521714161819-15534968fc5f.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

// React and Fluent UI v6.x
export default ({ data }) => (
	<Stack
		horizontalAlign="center"
		verticalAlign="center"
		verticalFill
		styles={{
			root: {
			width: "960px",
			margin: "0 auto",
			//textAlign: "center",
			color: "#605e5c"
			},
		}}
		className="App"
		gap={30}
		padding={15}
	>

		<Stack gap={15} padding={15} styles={stackCardStyle} horizontalAlign="start">
			<Text variant="xxLarge" styles={boldStyle} as="h1">
				Do Systems 365
			</Text>
			<Text as="p" styles={boldStyle}>
				Enabling your employees to succeed with Microsoft 365.
			</Text>
			<Text as="p">
				Imagine seeing an increase in your customer satisfaction / NPS scores. Your employee survey results / E-NPS scores become more positive. Your organization sees better results overall and becomes more profitable.
			</Text>
			<Text as="p">
				Do Systems 365 to make your Microsoft 365 and SharePoint extranet more effective. We execute a straightforward process to quickly take the digital transformation of any Microsoft 365 Business cloud / extranet to the next level.
			</Text>
		</Stack>
		
		<Stack gap={15} padding={15} styles={stackCardStyle} horizontalAlign="start">
			<Text as="p" styles={boldStyle}>
				Enable your employees to consistently follow <Text styles={underlineStyle}>processes</Text> and to find <Text styles={underlineStyle}>information</Text> more easily.
			</Text>
			<Text as="p">
				We believe that poorly-managed information and processes are costing businesses. When a business does a great job of this then, most employees will give you a similar answer to the same questions.
			</Text>
			<Text as="p">
				We also believe that defining and communicating processes in a way that enables employees to consistently follow along will. Being able to capture the changes to a process over time is important, and so is reporting on it.
			</Text>
			<Text as="p">
				By improving these 2 areas we can significantly reduce cost and increase profits.
			</Text>
			<Text as="p">
				<PrimaryButton
					href="https://forms.office.com/Pages/ResponsePage.aspx?id=fWh81j0cFEqMc-ZfFNOvSgpS0foFwkhCi2Of1VeMXOFUMzhKQlZSTkpCSEFKRU85QkdEMk5MWUxNRS4u"
				>Contact Us</PrimaryButton>
			</Text>
		</Stack>

		<Stack gap={0} padding={15} styles={stackCardStyle} horizontalAlign="start">
			<Text variant="large" styles={boldStyle} as="h2">
				Internal Processes
			</Text>
			<Stack gap={15} padding={0} styles={stackCardStyle} horizontal>
				<StackItem grow>
					<Stack gap={15} styles={stackCardStyle} horizontalAlign="start">
						<Text as="p" styles={boldStyle}>
							More visibility to management of your internal processes with measurable outcomes.
						</Text>
						<Text as="p">
							We document your internal processes and build them in to systems that employees can use, such as an online form or mobile app. Information from form input gets organized within your extranet. The employee can use it to follow a process such as when communicating to customers or when working with a customer. We can do things such as generate dynamic dashboards or data tables that let you analyze or update information.
						</Text>
					</Stack>
				</StackItem>
				<StackItem grow>
					{/* <Image 
						src={photo2} 
						width={300}
						alt="Two people discuss UI Design for a web application">
					</Image> */}					
					<Img
						fixed={data.photoProcess.childImageSharp.fixed}
						alt="Two people discuss UI Design for a web application"
					/>
				</StackItem>
			</Stack>
		</Stack>

		<Stack gap={0} padding={15} styles={stackCardStyle} horizontalAlign="start">
			<Text variant="large" styles={boldStyle} as="h2">
				Information Management
			</Text>
			<Stack gap={15} padding={0} styles={stackCardStyle} horizontal>
				<StackItem grow>
					<Stack gap={15} styles={stackCardStyle} horizontalAlign="start">
						<Text as="p" styles={boldStyle}>
							Corporate information is made very clear to employees who are trained on how to find it.
						</Text>
						<Text as="p">
							Using information management best practices, we will improve the organization of information on your extranet, such as by building a company wiki (aka “About us”, “Company Information” or “Center of Excellence”). Content such as Word documents or PDFs will be tagged with metadata to make it easier for employees to find information. We usually start by defining your functional classification to determine what metadata will work best within your extranet. Then we put it to use in a wiki and focus on using it in another area of your intranet to get you going. We can help you find innovative ways to make use of that metadata and content.			
						</Text>
					</Stack>
				</StackItem>
				<StackItem grow>
					{/* <Image 
						src={photo2}
						width={300}
						alt="Spiderman reads a book titled, &quote;The Decision Book&quote;">
					</Image> */}
					<Img
						fixed={data.photoSpiderman.childImageSharp.fixed}
						alt="Spiderman reads a book titled, &quote;The Decision Book&quote;"
					/>
				</StackItem>
			</Stack>
		</Stack>

		<Stack gap={15} padding={15} styles={stackCardStyle} horizontalAlign="start">
			<Text variant="large" styles={boldStyle} as="h2">
				We love
			</Text>
			<Text as="p">
				<Text styles={heartStyle}>❤</Text> Information management and data design.
			</Text>
			<Text as="p">
				<Text styles={heartStyle}>❤</Text> Productivity tools and systems for getting things done.
			</Text>
			<Text as="p">
				<Text styles={heartStyle}>❤</Text> We love developing quality websites, web and mobile apps.
			</Text>
			<Text as="p">
				<Text styles={heartStyle}>❤</Text> We love learning more about a business and creating digital solutions to problems.
			</Text>
			<Text as="p">
				But more importantly for you, we are experienced with enterprise-level SharePoint and Microsoft 365 Administration & Development.
			</Text>
			<Text as="p">
				<Text styles={heartStyle}>❤</Text> We love seeing a well-organized extranet.
			</Text>
			<Text as="p">
				Do Systems 365 combines all of this passion and experience in to a simple, straightforward process. We think you will love it, too.
			</Text>
		</Stack>

		<Stack gap={15} padding={15} styles={stackCardStyle} horizontalAlign="start">
			<Text variant="large" styles={boldStyle} as="h2">
				Experience
			</Text>
			<Text as="p" styles={boldStyle}>
				We use Microsoft 365 Business Basic to manage our own consulting firm.
			</Text>
			<Text as="p">
				Digital transformation, Microsoft 365 (Office 365 / O365), SharePoint Online, OneNote, Microsoft Teams, Microsoft To Do, Microsoft Planner.
			</Text>
			<Text as="p" styles={boldStyle}>
				We have built and managed enterprise-level SharePoint intranets for over 5 years.
			</Text>
			<Text as="p">
				Information Management, Information Governance, Workflows, SharePoint 2010, SharePoint 2013, SharePoint 2016, SharePoint 2019, SharePoint Online, SharePoint Administration, SharePoint sites, SharePoint Development (SPFx, React, PnP).
			</Text>
			<Text as="p" styles={boldStyle}>
				We have planned and developed many web and mobile applications for 15 years.
			</Text>
			<Text as="p">
				JavaScript, ReactJS, React Native, HTML, CSS, ASP.NET Core, Node.js, RESTful APIs, GraphQL, SQL, DynamoDB, Cloud, Serverless, Azure, Azure DevOps, Microsoft Identity, AWS.
			</Text>
		</Stack>

		<Stack gap={15} padding={15} styles={stackCardStyle} horizontalAlign="start">
			<Text variant="large" styles={boldStyle} as="h2">
				Location
			</Text>
			<Text as="p" styles={boldStyle}>
				We are located in Calgary, Alberta 🍁 Canada.
			</Text>
			<Text as="p">
				Is your organization located outside of Alberta? We operate within the Mountain Standard Time (MST) zone.
			</Text>
		</Stack>

		<Text as="p">
			<DefaultButton
				href="https://forms.office.com/Pages/ResponsePage.aspx?id=fWh81j0cFEqMc-ZfFNOvSgpS0foFwkhCi2Of1VeMXOFUMzhKQlZSTkpCSEFKRU85QkdEMk5MWUxNRS4u"
				//https://developer.microsoft.com/en-us/fluentui?fabricVer=6#/controls/web/button
			>
				Contact Us
			</DefaultButton>
		</Text>

		{/*
		<img
			src="https://raw.githubusercontent.com/Microsoft/just/master/packages/just-stack-uifabric/template/src/components/fabric.png"
			alt="logo"
		/>
		<Text variant="xxLarge" styles={boldStyle} as="h1">
			Welcome to Your UI Fabric App
		</Text>
		<Text variant="large">
			For a guide on how to customize this project, check out the UI Fabric
			documentation.
		</Text>
		<Text variant="large" styles={boldStyle}>
			Essential Links
		</Text>
		<Stack horizontal gap={15} horizontalAlign="center">
			<Link href="https://developer.microsoft.com/en-us/fabric">Docs</Link>
			<Link href="https://stackoverflow.com/questions/tagged/office-ui-fabric">
			Stack Overflow
			</Link>
			<Link href="https://github.com/officeDev/office-ui-fabric-react/">
			Github
			</Link>
			<Link href="https://twitter.com/officeuifabric">Twitter</Link>
		</Stack>
		<Text variant="large" styles={boldStyle}>
			Design System
		</Text>
		<Stack horizontal gap={15} horizontalAlign="center">
			<Link href="https://developer.microsoft.com/en-us/fabric#/styles/icons">
			Icons
			</Link>
			<Link href="https://developer.microsoft.com/en-us/fabric#/styles/typography">
			Typography
			</Link>
			<Link href="https://developer.microsoft.com/en-us/fabric#/styles/themegenerator">
			Theme
			</Link>
		</Stack>
		*/}
		
	</Stack>
);

/*
const App: React.FunctionComponent = () => {
  return (
	<Stack ....>
	  ...
	</Stack>
  )
}
export default App
*/
